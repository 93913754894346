import {
  Box,
  Button,
  Skeleton,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Container,
  Tr,
  useMediaQuery,
  Td,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CompanyService from '../../services/company-service';
import style from '../../assets/style.json';
import { TrashIcon } from '../../assets/TrashIcon';
import Swal from 'sweetalert2';

function CompanyOnboarding() {
  const { t } = useTranslation();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  let { companyId } = useParams();
  const [isLoad, toggleIsLoad] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<any | null>(null);

  const load = async () => {
    try {
      toggleIsLoad(true);
      if (companyId !== undefined) {
        const company = await CompanyService.read(companyId);
        setSelectedCompany(company);
      }
    } finally {
      toggleIsLoad(false);
    }
  };

  const handleFileInput = async (e: any) => {
    const file = e.target!.files[0];
    if (!file) return;
    if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
      // await risksFilesService.uploadFile(file, selectedCompany.id, riskId);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target?.result;
        console.log(data);
      };
    } else {
      Swal.fire({
        icon: 'error',
        title: t('adminOnboarding.errorTitle'),
        text: t('adminOnboarding.errorText'),
      });
    }
    load();
  };

  useEffect(() => {
    load();
  }, []);

  return (
    //@ts-ignore
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      minH="100vh"
      py={20}
      centerContent
    >
      <Text
        color={'white'}
        dir="rtl"
        marginBottom={'77px'}
        fontWeight={500}
        fontSize={32}
      >
        {t('adminOnboarding.title')}
      </Text>
      <Box
        w="100%"
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        maxW={style['pc-view']}
        p={'21px'}
        rounded="10px"
        bg="white"
      >
        {selectedCompany && (
          <Box
            display="flex"
            rounded={'10px'}
            gap="21px"
            padding="21px"
            bg="#F5F8FA"
            flexDirection={isPc ? 'row' : 'column'}
          >
            <Box
              display={'flex'}
              width="100%"
              flexDirection={isPc ? 'row' : 'column'}
              justifyContent={isPc ? 'space-between' : 'center'}
              alignItems="center"
              gap="16px"
            >
              <Box>
                <Text
                  textAlign={isPc ? 'start' : 'center'}
                  color={'0E314E'}
                  fontWeight="600"
                  fontSize="22px"
                >
                  {selectedCompany.name}
                </Text>
              </Box>
              <Button
                color={'white'}
                minW={isPc ? '200px' : 'full'}
                height="60px"
                textAlign="center"
                border="3px solid #FFFFFF"
                background={'#3FB2D2'}
                fontSize="18px"
                fontWeight={700}
                rounded={'100px'}
                filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
                display="flex"
                gap="8px"
                alignItems="center"
                onClick={() => {
                  const fileInput = document.getElementById(
                    'fileInput',
                  ) as HTMLInputElement;
                  fileInput.click();
                }}
              >
                {t('adminOnboarding.loadPhones')}
              </Button>
            </Box>
          </Box>
        )}

        {!isLoad ? (
          <>
            {selectedCompany && (
              <>
                <Table size="sm">
                  <Thead borderBottom={'1.5px solid #DFDFDF'}>
                    <Tr opacity={0.7}>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('adminOnboarding.phone')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('adminOnboarding.remove')}
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {selectedCompany.employees
                      .filter((e: any) => e.isUnlisted)
                      .map((e: any, index: number) => {
                        return (
                          <Tr
                            key={`employee-${index}`}
                            rounded={'10px'}
                            border={'none'}
                            position="relative"
                          >
                            <Td
                              roundedRight={'10px'}
                              bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                              border={'none'}
                              textAlign="start"
                            >
                              {e.phone}
                            </Td>
                            <Td
                              roundedLeft={'10px'}
                              bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                              border={'none'}
                              textAlign="start"
                            >
                              <Button
                                textAlign="center"
                                fontSize="18px"
                                fontWeight={700}
                                rounded={'100px'}
                                bg={'#fff'}
                                color={style['primary-color']}
                              >
                                <Box
                                  gap={'17px'}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  <Text>{t('adminOnboarding.remove')}</Text>
                                  <TrashIcon />
                                </Box>
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </>
            )}
          </>
        ) : (
          <Box className="flex w-full gap-3">
            <Skeleton height="50px" width={'80%'} />
            <Skeleton height="50px" width={'20%'} />
          </Box>
        )}
      </Box>
      <input
        type="file"
        id="fileInput"
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={(e) => handleFileInput(e)}
      ></input>
    </Container>
  );
}

export default CompanyOnboarding;
