import { Box, Button } from '@chakra-ui/react';
import { Dispatch } from 'react';

type QuestionListProps = {
  questions: any[];
  onboardingAnswers: any;
  setOnboardingAnswers: Dispatch<React.SetStateAction<any>>;
  isMultipleChoice?: boolean;
};

function QuestionList({
  questions,
  onboardingAnswers,
  setOnboardingAnswers,
  isMultipleChoice = false,
}: QuestionListProps) {
  const toggleOption = (questionId: string, option: string) => {
    setOnboardingAnswers((prevAnswers: any) => {
      const selectedOptions = prevAnswers[questionId] || [];
      if (isMultipleChoice) {
        const newSelectedOptions = selectedOptions.includes(option)
          ? selectedOptions.filter(
              (selectedOption: string) => selectedOption !== option,
            )
          : [...selectedOptions, option];
        return {
          ...prevAnswers,
          [questionId]: newSelectedOptions,
        };
      }
      return {
        ...prevAnswers,
        [questionId]: prevAnswers[questionId] === option ? null : option,
      };
    });
  };

  return (
    <>
      {questions.map((question) => {
        const selectedOptions = onboardingAnswers[question.id] || [];
        const toggleQuestionOption = (option: string) => {
          toggleOption(question.id, option);
        };
        return (
          <Box key={question.id}>
            <Box>{question.content}</Box>
            <Box display="flex" gap={2} flexWrap="wrap">
              {question.options.map((option: any, index: number) => {
                const isSelected = selectedOptions.includes(option);
                const onClick = () => toggleQuestionOption(option);
                return (
                  <Button
                    variant={isSelected ? 'solid' : 'outline'}
                    flexGrow={1}
                    onClick={onClick}
                    key={index}
                  >
                    {option}
                  </Button>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </>
  );
}

export default QuestionList;
