import {
  Box,
  Button,
  Container,
  Input,
  Textarea,
  Checkbox,
  SlideFade,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import newslatterService from '../../services/newslatter-service';
import UserService from '../../services/user-service';
import style from '../../assets/style.json';
import CompanyService from '../../services/company-service';
import { appConfig } from '../../configs/app';

import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { Switch } from '../../components/Switch';
import { useTranslation } from 'react-i18next';
import { getMimeType } from '../../services/mime';

const FileSaver = require('file-saver');
const mime = require('mime');

const ModifyNewslattter = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<any>({});
  const [companies, setCompanies] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [newslatter, setNewslatter] = useState<any>({
    title: '',
    content: '',
    link: '',
    file: null,
  });
  const [isValid, setIsValid] = useState(false);
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<any>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const [file, setFile] = useState<any | null>(null);

  useEffect(() => {
    load();
    updateNewslatter();
  }, []);

  useEffect(() => {
    setIsValid(
      newslatter.title.trim().length > 1 &&
        newslatter.content.trim().length > 1,
    );
  }, [newslatter]);

  const submit = async () => {
    setSubmitting(true);
    await newslatterService.modifyFormData(
      { ...newslatter, isShowVideo: !!newslatter.isShowVideo },
      file,
      newslatter.filePath,
      selectedCompaniesIds,
    );
    setSubmitting(false);
    navigate('/newslatter');
  };

  const updateNewslatter = async () => {
    if (id) {
      const selectedNewletter = await newslatterService.read(id);
      setNewslatter(selectedNewletter);

      setSelectedCompaniesIds(selectedNewletter.companies ?? []);
    }
  };
  console.log(newslatter);

  const load = async () => {
    const user = await UserService.info();
    setUserInfo(user);

    const companies = await CompanyService.list();
    setCompanies(companies);
  };

  const uploadFile = async () => {
    if (file?.name || newslatter.filePath) {
      const result = await Swal.fire({
        title:
          t('modifyNewsletter.deleteFile') +
          (file?.name || newslatter.fileName) +
          '?',
        text: t('modifyNewsletter.deleteWarning'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: t('modifyNewsletter.cancel'),
        confirmButtonText: t('modifyNewsletter.delete'),
      });
      if (result.isConfirmed) {
        setFile(null);
        setNewslatter({ ...newslatter, filePath: undefined });
      }
      return;
    }

    const input = document.getElementById('fileInput');
    input?.click();
  };

  const handleFileInput = (e: any) => {
    // @ts-ignore
    const file = e.target!.files[0];
    setFile(file);
  };

  const downloadFile = async (fileName: string, path: string) => {
    /*
        DO
        NOT
        TOUCH
    */
    console.log(fileName);
    console.log(path);

    // now you can touch
    let response = await fetch(path);
    let data = await response.blob();

    let splittedFileName = fileName.split('.');
    let extension = splittedFileName.at(splittedFileName.length - 1);

    let fixedData = new Blob([data], { type: getMimeType(extension ?? '') });
    FileSaver.saveAs(fixedData, fileName);
  };
  console.log(file);

  return (
    <Container
      maxW="full"
      minH="100vh"
      position={'absolute'}
      top={'90px'}
      py={20}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {t('modifyNewsletter.addTraining')}
        </Text>
      </SlideFade>

      <input
        type="file"
        id="fileInput"
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={(e) => handleFileInput(e)}
      ></input>
      <Box
        padding={'21px'}
        maxW={style['pc-view']}
        w="100%"
        bg="#fff"
        boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
        rounded="10px"
        display={'flex'}
        flexDirection={'column'}
        gap="20px"
      >
        <Box
          display={'flex'}
          flexDirection="column"
          gap={'21px'}
          rounded={'10px'}
          padding={'21px'}
          bg="#F5F8FA"
        >
          <Text
            fontSize={'22px'}
            fontWeight="600"
            color={'#0E314E'}
            lineHeight="30px"
          >
            {t('modifyNewsletter.generalDetails')}
          </Text>
          <Box>
            <Text
              fontSize={'14px'}
              fontWeight="400"
              color={'#aaa'}
              lineHeight="19px"
              pb={'5px'}
            >
              {t('modifyNewsletter.title')}
            </Text>
            <Input
              bg={'#fff'}
              border="1.2521px solid #E2E2E2"
              fontSize={'18px'}
              fontWeight="400"
              maxLength={128}
              placeholder={t('modifyNewsletter.titleDescription')}
              isRequired
              value={newslatter.title}
              onChange={(e) =>
                setNewslatter({ ...newslatter, title: e.target.value })
              }
            />
          </Box>

          <Box display={'flex'} gap="16px" alignItems="center">
            <Text>{t('modifyNewsletter.video')}</Text>
            <Switch
              isCheck={newslatter.isShowVideo}
              toggleIsCheck={() => {
                if (newslatter.isShowVideo) {
                  setNewslatter({
                    ...newslatter,
                    link: '',
                    isShowVideo: false,
                  });
                } else {
                  setFile(null);
                  setNewslatter({
                    ...newslatter,
                    link: '',
                    isShowVideo: true,
                    filePath: '',
                  });
                }
              }}
              children={null}
            />
            <Text>{t('modifyNewsletter.picture')}</Text>
          </Box>
          {newslatter.isShowVideo ? (
            <Box width={'full'}>
              <Text
                fontSize={'14px'}
                fontWeight="400"
                color={'#aaa'}
                lineHeight="19px"
                pb={'5px'}
              >
                {t('modifyNewsletter.video')}
              </Text>
              <Input
                bg={'#fff'}
                height={'52px'}
                border="1.2521px solid #E2E2E2"
                fontSize={'18px'}
                fontWeight="400"
                maxLength={128}
                placeholder={t('modifyNewsletter.linkTo') + ' YouTube'}
                isRequired
                value={newslatter.link}
                disabled={!newslatter.isShowVideo}
                onChange={(e) =>
                  setNewslatter({ ...newslatter, link: e.target.value })
                }
              />
            </Box>
          ) : (
            <Box width={'full'}>
              <Text
                fontSize={'14px'}
                fontWeight="400"
                color={'#aaa'}
                lineHeight="19px"
                pb={'5px'}
              >
                {t('modifyNewsletter.picture')}
              </Text>
              <Box
                height={'52px'}
                bg="#fff"
                rounded={'10px'}
                border="1.2521px solid #E2E2E2"
                display={'flex'}
                paddingBlock="9px"
                paddingInline="12px"
                justifyContent="space-between"
                textAlign="center"
                alignItems="center"
              >
                <Text
                  textOverflow={'ellipsis'}
                  overflow="hidden"
                  whiteSpace={'nowrap'}
                  fontSize={'14px'}
                  paddingInlineEnd="10px"
                  fontWeight="400"
                  color={'#aaa'}
                >
                  {file || newslatter.filePath
                    ? file
                      ? file.name
                      : newslatter.fileName
                    : t('modifyNewsletter.choosePicture')}
                </Text>
                <Button
                  fontSize={14}
                  noOfLines={1}
                  onClick={() => uploadFile()}
                  color={'white'}
                  flexShrink={0}
                  bg={file || newslatter.filePath ? 'red' : '#3FB2D2'}
                  disabled={newslatter.isShowVideo}
                >
                  {file || newslatter.filePath
                    ? t('modifyNewsletter.deletePicture')
                    : t('modifyNewsletter.choosePicture')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection="column"
          gap={'21px'}
          rounded={'10px'}
          padding={'21px'}
          bg="#F5F8FA"
        >
          <Text
            fontSize={'22px'}
            fontWeight="600"
            color={'#0E314E'}
            lineHeight="30px"
          >
            {t('modifyNewsletter.recipients')}
          </Text>
          <Box>
            <Text
              fontSize={'14px'}
              fontWeight="400"
              color={'#aaa'}
              lineHeight="19px"
              pb={'5px'}
            >
              {t('modifyNewsletter.recipients')}
            </Text>
            <Box position={'relative'}>
              <Menu closeOnSelect={false}>
                <MenuButton as={Box}>
                  <Box
                    bg={'#fff'}
                    border="1.2521px solid #E2E2E2"
                    fontSize={'18px'}
                    fontWeight="400"
                    width="100%"
                    rounded={'7px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={'space-between'}
                    flexDirection="row"
                    paddingBlock="9px"
                    paddingInline="12px"
                  >
                    {selectedCompaniesIds.length > 0 ? (
                      <Text fontSize={'18px'} fontWeight="400" color={'#aaa'}>
                        (
                        {t('modifyNewsletter.recipients') +
                          ' ' +
                          selectedCompaniesIds.length}
                        )
                      </Text>
                    ) : (
                      <Text fontSize={'18px'} fontWeight="400" color={'#aaa'}>
                        ({t('modifyNewsletter.companyName')})
                      </Text>
                    )}
                    <ArrowDownIcon />
                  </Box>
                </MenuButton>
                <div className="companiesParent">
                  <MenuList width={'100%'} maxHeight={300} overflow={'scroll'}>
                    <MenuItem>
                      <Button
                        variant="link"
                        color="blue.400"
                        onClick={() => {
                          setSelectedCompaniesIds(
                            selectedCompaniesIds.length === companies.length
                              ? []
                              : companies.map((i: any) => i.id),
                          );
                        }}
                      >
                        {t('modifyNewsletter.selectAll')}
                      </Button>
                    </MenuItem>

                    {companies.map((company: any) => {
                      return (
                        <MenuItem key={company.id}>
                          <Checkbox
                            size="lg"
                            colorScheme="white"
                            borderColor="#3FB2D2"
                            iconColor="#3FB2D2"
                            iconSize={'17px'}
                            isChecked={selectedCompaniesIds.includes(
                              company.id,
                            )}
                            onChange={() => {
                              setSelectedCompaniesIds(
                                selectedCompaniesIds.includes(company.id)
                                  ? selectedCompaniesIds.filter(
                                      (i: any) => i !== company.id,
                                    )
                                  : [...selectedCompaniesIds, company.id],
                              );
                            }}
                          >
                            {company.name}
                          </Checkbox>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </div>
              </Menu>
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection="column"
          gap={'21px'}
          rounded={'10px'}
          padding={'21px'}
          bg="#F5F8FA"
        >
          <Text
            fontSize={'22px'}
            fontWeight="600"
            color={'#0E314E'}
            lineHeight="30px"
          >
            {t('modifyNewsletter.content')}
          </Text>
          <Box>
            <Text
              fontSize={'14px'}
              fontWeight="400"
              color={'#aaa'}
              lineHeight="19px"
              pb={'5px'}
            >
              {t('modifyNewsletter.title')}
            </Text>
            <Textarea
              bg={'#fff'}
              border="1.2521px solid #E2E2E2"
              fontSize={'18px'}
              fontWeight="400"
              placeholder={t('modifyNewsletter.enterContent')}
              isRequired
              value={newslatter.content}
              onChange={(e) =>
                setNewslatter({ ...newslatter, content: e.target.value })
              }
              minHeight={400}
            />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection="row-reverse">
          <Button
            color={'white'}
            minW={isPc ? '200px' : 'full'}
            height="60px"
            textAlign="center"
            border="3px solid #FFFFFF"
            background={'#3FB2D2'}
            fontSize="18px"
            fontWeight={700}
            rounded={'100px'}
            filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
            onClick={submit}
          >
            {t('modifyNewsletter.save')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default ModifyNewslattter;
