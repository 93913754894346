import axios from "axios";
import Swal from "sweetalert2";
import { appConfig } from "../configs/app";

class newslatterService {
  static async read(id: string) {
    const response = await axios.get(`${appConfig.apiUrl}/newslatter/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const data = response.data;
    return data;
  }

  static async delete(id: string) {
    await axios.delete(`${appConfig.apiUrl}/newslatter/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
  }

  static async list() {
    const response = await axios.get(`${appConfig.apiUrl}/newslatter`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const data = response.data;
    return data;
  }

  static async modify(newslatter: any = {}) {
    const response = await axios.post(
      `${appConfig.apiUrl}/newslatter`,
      newslatter,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const data = response.data;
    return data;
  }

  static async modifyFormData(
    newslatter: any = {},
    file: File | null = null,
    filePath: string | undefined,
    companies: string[] = []
  ) {
    const formData = new FormData();
    if (newslatter.id !== undefined) {
      formData.append("id", newslatter.id);
    }

    if (file !== null) {
      formData.append("file", file);
    } else {
      formData.append("filePath", filePath ?? "");
    }

    formData.append("title", newslatter.title);
    formData.append("content", newslatter.content);
    formData.append("link", newslatter.link);
    formData.append("isShowVideo", newslatter.isShowVideo);
    formData.append("companies", companies.toString());

    const response = await axios.post(
      `${appConfig.apiUrl}/newslatter`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = response.data;
    return data;
  }
}

export default newslatterService;
