import {
  Box,
  Container,
  Skeleton,
  useMediaQuery,
  Flex,
  Stack,
  SkeletonText,
} from '@chakra-ui/react';

const DashboardSkelenton: React.FC = () => {
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  return (
    <>
      <Flex justify="center" mb={10}>
        <Box height="40px" />
      </Flex>
      <Flex justify="center" mb={10}>
        <Skeleton height="30px" width="200px" borderRadius="md" />
      </Flex>
      <Flex justify="space-around" wrap="wrap">
        <Box
          p={5}
          shadow="md"
          borderWidth="1px"
          justifyContent={'center'}
          alignItems={'center'}
          flex="1"
          borderRadius="md"
          textAlign="center"
          m={2}
          backgroundColor="white"
          width="300px" // Fixed width
        >
          <Stack spacing={4} width={'100%'} alignItems={'center'}>
            <SkeletonText
              mt="4"
              noOfLines={1}
              spacing="4"
              skeletonHeight="4"
              width={200}
              justifyContent={'center'}
              alignContent={'center'}
              textAlign={'center'}
            />
            <SkeletonText
              mt="4"
              noOfLines={1}
              spacing="4"
              skeletonHeight="4"
              width={150}
              justifyContent={'center'}
              alignContent={'center'}
              textAlign={'center'}
            />

            <Box mt={3} mb={3}>
              <Skeleton height="150px" width="150px" borderRadius="50%" />
            </Box>
            <Skeleton height="40px" width="200px" borderRadius="3xl" />
          </Stack>
        </Box>
        <Box
          p={5}
          shadow="md"
          borderWidth="1px"
          flex="1"
          borderRadius="md"
          textAlign="center"
          m={2}
          backgroundColor="white"
          width="300px" // Fixed width
        >
          <Stack spacing={4} alignItems={'center'}>
            <Skeleton height="40px" width="100%" borderRadius="md" mb={4} />
            <Skeleton
              height="1px"
              width="100%"
              mt={5}
              mb={5}
              borderRadius="md"
            />
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
              rounded="14px"
              width="85px"
              height={'85px'} // Adjusted height to ensure proper centering
              bg={'#F5F8FA'}
              marginInline="auto"
              padding={'0px'}
              marginTop="20px"
            >
              <Skeleton height="85px" width="85px" borderRadius="md" />
            </Box>
            <Skeleton height="40px" width="200px" borderRadius="3xl" />
            <Skeleton height="40px" width="200px" borderRadius="3xl" />
          </Stack>
        </Box>
        <Box
          p={5}
          shadow="md"
          borderWidth="1px"
          justifyContent={'center'}
          alignItems={'center'}
          flex="1"
          borderRadius="md"
          textAlign="center"
          m={2}
          backgroundColor="white"
          width="300px" // Fixed width
        >
          <Stack spacing={4} width={'100%'} alignItems={'center'}>
            <SkeletonText
              mt="4"
              noOfLines={1}
              spacing="4"
              skeletonHeight="4"
              width={200}
              justifyContent={'center'}
              alignContent={'center'}
              textAlign={'center'}
            />
            <SkeletonText
              mt="4"
              noOfLines={1}
              spacing="4"
              skeletonHeight="4"
              width={150}
              justifyContent={'center'}
              alignContent={'center'}
              textAlign={'center'}
            />

            <Box mt={3} mb={3}>
              <Skeleton height="150px" width="150px" borderRadius="50%" />
            </Box>
            <Skeleton height="40px" width="200px" borderRadius="3xl" />
          </Stack>
        </Box>
        <Box
          p={5}
          shadow="md"
          borderWidth="1px"
          flex="1"
          borderRadius="md"
          textAlign="center"
          m={2}
          backgroundColor="white"
          width="300px" // Fixed width
        >
          <Stack spacing={4} alignItems={'center'}>
            <Skeleton height="40px" width="100%" borderRadius="md" mb={4} />
            <Skeleton
              height="1px"
              width="100%"
              mt={5}
              mb={5}
              borderRadius="md"
            />
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
              rounded="14px"
              width="85px"
              height={'85px'} // Adjusted height to ensure proper centering
              bg={'#F5F8FA'}
              marginInline="auto"
              padding={'0px'}
              marginTop="20px"
            >
              <Skeleton height="85px" width="85px" borderRadius="md" />
            </Box>
            <Skeleton height="40px" width="200px" borderRadius="3xl" />
            <Skeleton height="40px" width="200px" borderRadius="3xl" />
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default DashboardSkelenton;
