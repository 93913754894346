import axios from 'axios';
import Swal from 'sweetalert2';
import { appConfig } from '../configs/app';

class CompanyService {
  static async read(id: string) {
    const response = await axios.get(`${appConfig.apiUrl}/company/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    const data = response.data;
    return data;
  }

  static async delete(name: string, id: string) {
    const result = await Swal.fire({
      title: `למחוק את החברה "${name}"?`,
      text: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע.',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'הקש סיסמה',
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ביטול',
      confirmButtonText: 'מחיקה',
    });
    if (result.isConfirmed) {
      console.log(result);

      try {
        await axios.delete(`${appConfig.apiUrl}/company/${id}`, {
          data: { password: result.value },
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
      } catch (err: any) {
        Swal.fire('', err.response.data.message, 'error');
      }
    }
  }

  static async list() {
    const response = await axios.get(`${appConfig.apiUrl}/companies`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    const data = response.data;
    return data;
  }

  static async checkAdminCode(code: string) {
    await axios.post(`${appConfig.apiUrl}/company/checkAdminCode`, {
      data: { password: code },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
  }

  static async modify(info: any = {}) {
    try {
      const response = await axios.post(`${appConfig.apiUrl}/company`, info, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log(response);
      if (response.status > 400) {
      }

      const data = response.data;
      return data;
    } catch (err: any) {
      Swal.fire('שגיאה', err?.response?.data?.message, 'error');
      throw err;
    }
  }

  static async getOnboardingList(info: any = {}) {
    try {
      const response = await axios.get(
        `${appConfig.apiUrl}/onboarding-questionnaire`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      const data = response.data;
      return data;
    } catch (err: any) {
      Swal.fire('שגיאה', err?.response?.data?.message, 'error');
      throw err;
    }
  }

  static async getOnboardingQuestionnaire() {
    try {
      const response = await axios.get(
        `${appConfig.apiUrl}/my-onboarding-questionnaire`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      const data = response.data;
      return data;
    } catch (err: any) {
      Swal.fire('שגיאה', err?.response?.data?.message, 'error');
      throw err;
    }
  }

  static async createOnboardingQuestionnaire(questions: any[], name: string) {
    try {
      const response = await axios.post(
        `${appConfig.apiUrl}/onboarding-questionnaire`,
        {
          id: '',
          name: name,
          questions: questions.map((question) => {
            return {
              ...question,
              id: '',
            };
          }),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      const data = response.data;
      return data;
    } catch (err: any) {
      Swal.fire('שגיאה', err?.response?.data?.message, 'error');
      throw err;
    }
  }

  // static async createUnlistedUsers(companyId: string, phones: string[]) {}
}

export default CompanyService;
