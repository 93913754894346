import axios from 'axios';
import { appConfig } from '../configs/app';

class TasklistService {
  static async progress(roleId: string) {
    const response = await axios.get(`${appConfig.apiUrl}/progress/${roleId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    const data = response.data;
    return data;
  }

  static async list(id?: string) {
    const response = await axios.get(
      `${appConfig.apiUrl}/tasklists${id ? `/${id}` : ''}`,
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } },
    );
    const data = response.data;
    return data;
  }

  static async listCompany(id: string) {
    const response = await axios.get(
      `${appConfig.apiUrl}/tasklists/company/${id}`,
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } },
    );
    const data = response.data;
    return data;
  }

  static async read(id?: string, userId?: string, all = false) {
    const response = await axios.get(
      `${appConfig.apiUrl}/tasklist/${id || ''}`,
      {
        params: { user_id: userId || '', all },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data;
  }

  static async create(info: any = {}, filesMap: any, userId?: string) {
    console.log({ info });
    const formData = new FormData();
    formData.append('info', JSON.stringify(info));
    formData.append('user_id', userId || '');
    Object.entries(filesMap).forEach(([key, files]) => {
      (files as any).forEach((file: any) => {
        let id = `item=${key}&file=${file.id}`;

        formData.append(id, file.file ? file.file : file.data);
      });
    });

    const response = await axios.post(
      `${appConfig.apiUrl}/tasklist`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    const data = response.data;
    return data;
  }

  static async delete(id: string) {
    const response = await axios.delete(
      `${appConfig.apiUrl}/tasklist/${id || ''}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data;
  }

  static async update(id: string, info: any = {}, fileMap: any) {
    const formData = new FormData();

    const response = await axios.put(
      `${appConfig.apiUrl}/tasklist/${id}`,
      info,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data;
  }

  static async submit(
    id: string,
    list: { id: string; checked: boolean }[],
    filesMap: Record<string, any[]> = {},
    userId?: string,
  ) {
    const formData = new FormData();
    formData.append('list', JSON.stringify(list));
    formData.append('user_id', userId || '');

    Object.entries(filesMap).forEach(([key, files]) => {
      files.forEach((file) => {
        let id = `item=${key}&file=${file.id}`;

        formData.append(id, file.file ? file.file : file.data);
      });
    });

    const response = await axios.post(
      `${appConfig.apiUrl}/tasklist/submit/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    const data = response.data;
    return data;
  }
}

export default TasklistService;
